import request from "./index";

// 获取应用列表信息
export const getAppListApi = (data) => {
  return request({
    url: `/api/chatApps/queryAllByPageBackend`,
    method: "POST",
    data,
  });
};

// 获取标签列表
export const getLabelListApi = (data) => {
  return request({
    url: `/api/chatLabel/queryByPage`,
    method: "POST",
    data,
  });
};
