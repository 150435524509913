<template>
  <van-nav-bar
    @click-left="onClickLeft"
    :left-text="showBack ? '返回' : ''"
    :left-arrow="showBack"
    @click-right="onClickRight"
  >
    <template #left v-if="customLeft">
      <div class="flex items-center">
        <img
          class="w-13 h-13"
          src="
https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/icon-close.png"
        />
      </div>
    </template>
    <template #title>
      <span class="title">{{ title }}</span>
    </template>
    <template v-if="showShare" #right>
      <img
        src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/share.svg"
      />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Elion Chat'
    },
    showBack: {
      type: Boolean,
      default: true
    },
    customLeft: {
      type: Boolean,
      default: false
    },
    showShare: {
      type: Boolean,
      default: true
    },
    leftText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },

  methods: {
    onClickLeft() {
      this.$router.back()
    },

    onClickRight() {}
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 25px;
}
.title {
  font-weight: bold;
  font-size: 18px;
  color: #212121;
}
</style>
