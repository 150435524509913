<template>
  <div ref="home" class="home">
    <!-- banner -->
    <div class="banner">
      <div class="main">
        <!-- 头部功能列表 Phase II code Author：Ethan-->
        <div class="logo-box">
          <img
            @click="gooFficialWebsite"
            src="@/assets/images/logos/ElionChatlogo@2x.png"
            alt=""
          />

          <!-- 右边 -->
          <div class="headerBtn">
            <!-- 功能区 -->
            <div class="functions">
              <div class="function_list_item" @click="imgClick">图像生成</div>
              <!-- <div class="function_list_item" @click="transportClick">
                同声传译
              </div> -->
              <div class="function_list_item" @click="parseClick">
                文档解析助手
              </div>
              <div class="function_list_item" @click="chatClick">
                智能聊天助手
              </div>
            </div>

            <!-- 体验移动端 -->
            <span
              class="experience_mobile"
              @click="mobileClick"
              @mouseover="isShowMobile = true"
              @mouseleave="isShowMobile = false"
            >
              <img src="@/assets/images/mobile.png" alt="" />
              体验移动端
              <!-- <span>体验移动端</span> -->
              <transition name="qrCode">
                <div v-show="isShowMobile" class="qr-code">
                  <img
                    src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/upload/img/ewm_1688459267943.jpg"
                    alt=""
                    srcset=""
                  />
                </div>
              </transition>
            </span>

            <!-- 功能区 -->
            <!-- <div
              class="more_function"
              @mousemove="isShowMore = true"
              @mouseleave="isShowMore = false"
            >
              更多功能
              <i
                :class="isShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                id="closeBoxMore"
                style="cursor: pointer"
              ></i>
              <div v-if="isShowMore" class="function_list">
                <div class="function_list_item" @click="imgClick">图像生成</div>
                <div class="function_list_item" @click="transportClick">
                  同声传译
                </div>
                <div class="function_list_item" @click="parseClick">
                  文档解析助手
                </div>
                <div class="function_list_item" @click="chatClick">
                  智能聊天助手
                </div>
                <div class="function_list_item" @click="adviceClick">
                  意见反馈
                </div>
              </div>
            </div> -->

            <!-- 用户头像 -->
            <div class="user" @click.stop="isShowBtn = !isShowBtn">
              <el-avatar :src="userInfo.headimgurl"></el-avatar>
              <div class="nickname" @click="handleLogin">
                {{ userInfo.nickname }}
              </div>

              <i
                v-if="userInfo.memberId"
                :class="isShowBtn ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                id="closeBox"
                style="cursor: pointer"
              ></i>
              <transition name="fade">
                <div v-show="isShowBtn && userInfo.memberId" class="userSelect">
                  <span @click="toPersonalCenterClick"> 个人中心 </span>
                  <span @click="adviceClick"> 用户反馈 </span>
                  <span @click="loginOut"> 退出登录 </span>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <!-- 搜索区域 -->
        <div class="search-box">
          <div class="title">Elion Chat</div>
          <div class="input-box home-input">
            <input
              type="text"
              v-model="value"
              placeholder="请输入你要咨询的问题"
              placeholder-class="placeholder"
              @keyup.enter="goChat"
            />
            <img @click="goChat" src="@/assets/images/send.png" alt="" />
          </div>
          <div @click="goFileParsing" class="document-box">
            <div class="document-icon"></div>
            <span>文档解析助手</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用包 -->
    <div class="toolkit main">
      <div class="title">
        <div class="title-left">
          <span class="icon"></span>
          <span>应用包</span>
        </div>

        <!-- 滚动横幅 Phase II code Author：Ethan-->
        <!-- <div v-if="isShowMsgBox" class="msg-box">
          <van-notice-bar
            class="msg-banner"
            left-icon="volume-o"
            :scrollable="false"
            text="ChatGPT 4.0 已经来了！更强大的分析系统，更强大的分析能力！快来尝试吧。"
            @click="bannerClick"
          />
          <div class="more-info" @click="bannerClick">
            了解详情
            <img src="@/assets/images/点击.png" alt="" srcset="" />
          </div>
          <i
            class="el-icon-close"
            style="color: #ed6a21"
            @click="closeMsgBox"
          ></i>
        </div> -->

        <!-- search -->
        <div class="flex">
          <div class="search">
            <input
              @keyup.enter="searchUse"
              v-model="keyword"
              @input="inputValue"
              type="text"
              placeholder="请输入关键词搜索"
            />
            <img
              v-if="idShowDel"
              @click="clear"
              src="@/assets/images/delete.png"
              alt=""
            />
            <img
              v-else
              @click="searchUse"
              src="@/assets/images/search.png"
              alt=""
            />
          </div>
          <div @click="handleOpenCreateApp" class="btn blue-btn">
            <img src="@/assets/images/establish.png" alt="" />
            <span>创建应用</span>
          </div>
        </div>
      </div>
      <!-- 应用包内容 -->
      <div class="toolkit-main">
        <!-- 导航 -->
        <div class="nav-box">
          <div
            v-for="(item, index) in navList"
            :key="index"
            :class="[active === index ? 'active-box' : '']"
            @click="() => clickNav(index, item.labelId)"
          >
            <div :class="[active === index ? 'active' : '']">
              <span class="icon" :class="`icon${index}`"></span>
              <span>{{ item.labelName }}</span>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="main">
          <div
            @click="() => goSubapplication(item)"
            v-for="(item, index) in toolList"
            :key="index"
          >
            <div class="name">{{ item.appTitle }}</div>
            <div class="info">{{ item.appDesc }}</div>
            <div class="arrows">
              <span
                >{{ Number(item.usedNum) + Number(item.prefixNum) }}
                <span style="color: #999">人在用</span></span
              >
              <span class="icon"></span>
            </div>
          </div>
          <div class="more">
            <div>
              <img src="@/assets/images/more-tool.png" alt="" />
              <div>更多应用即将发布</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 协议弹框 -->
    <PopUp ref="popUp" />
    <!-- 添加应用弹框 -->
    <PopUpAddUse ref="popUpAddUse" @isOK="getAppList" />

    <!-- 二维码登录弹窗 -->
    <el-dialog
      title=""
      :visible.sync="isShowDialog"
      top="200px"
      width="30%"
      center
      :show-close="false"
    >
      <wxlogin
        :appid="appid"
        :scope="'snsapi_login'"
        :theme="'black'"
        :redirect_uri="redirect_uri"
        :state="state"
        href=""
        rel="external nofollow"
        style="display: flex; justify-content: center"
      >
      </wxlogin>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfoApi, loginOutApi } from '@/api/user'
import { getLeaveWordsApi } from '@/api/member'
import { getAppListApi, getLabelListApi } from '@/api/home'
// 协议弹框
import PopUp from '@/components/PopUp'
// 添加应用
import PopUpAddUse from '@/components/PopUpAddUse'
// 微信扫描二维码登录组件
import wxlogin from 'vue-wxlogin'
import { useUserStore } from '@/store/user'

export default {
  components: { PopUp, PopUpAddUse, wxlogin },
  data() {
    return {
      userstore: useUserStore(),
      // 应用包
      navList: [],
      active: 0,
      toolList: [],
      // 输入咨询的问题值
      value: '',
      // 搜索应用值
      keyword: '',
      idShowDel: false,
      isShowBtn: false,
      isShowMore: false,
      isShowMobile: false,
      isShowMsgBox: true,
      userInfo: {
        nickname: '请登录',
        headimgurl:
          'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      },
      /**微信扫码登录 */
      appid: 'wxaef7cf404f051a6f',
      // redirect_uri: 'http://192.168.1.81:8080/',
      // redirect_uri: "http://ai.yilianzh.com/",
      redirect_uri: 'https://ai.yilianzh.com/',
      state: '',
      href: '', // 自定义样式链接
      // 弹窗显隐
      isShowDialog: false
    }
  },

  created() {
    let navList = sessionStorage.getItem('navList')
      ? JSON.parse(sessionStorage.getItem('navList'))
      : []
    if (navList && navList.length) {
      this.navList = navList
    } else {
      this.getLabelList()
    }

    // let toolList = sessionStorage.getItem("toolList")
    //   ? JSON.parse(sessionStorage.getItem("toolList"))
    //   : [];
    // if (toolList && toolList.length) {
    //   this.toolList = toolList;
    // } else {
    //   this.getAppList();
    // }

    // 本地测试用
    // sessionStorage.setItem(
    //   'userInfo',
    //   '{"isAdmin":"2","isOperation":"1","memberId":"1892130797415112705","openid":"oS11W6ODFgRczkNuF8fJBslLNCZA","nickname":"生活不易-小张叹气","sex":"0","province":"","city":"","country":"","headimgurl":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqgtBDywvjS9Tia5KLVBIaLWUOp7OamXJzr8JSpibFKM2PzSwk03KcC1aAIWPfQVWLEj7rG4rbXDW2Q/132","unionid":"o9mX35raIw6eLuihkpttMlRTWWo0","createTime":"2025-02-19 16:35:11","memberType":{"value":"2","label":"免费会员"},"invitationCode":"777963"}'
    // )
    // sessionStorage.setItem('memberId', '1892130797415112705')
    // sessionStorage.setItem(
    //   'token',
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3ZWIiLCJpc3MiOiJlbGlvbi1jaGF0IiwiZXhwIjoxNzQzMTQzMjE4LCJpYXQiOjE3NDA1NTEyMTgsImp0aSI6IjE4OTIxMzA3OTc0MTUxMTI3MDUiLCJ1c2VybmFtZSI6IueUn-a0u-S4jeaYky3lsI_lvKDlj7nmsJQifQ.kJZvX4ttbSNZqP94wapzll-fT5bKuSSWUShPS--iQcU'
    // )
  },

  mounted() {
    this.getWindowUrlCode()
    this.getAppList()
    if (sessionStorage.userInfo) {
      this.userInfo = JSON.parse(sessionStorage.userInfo)
    }
  },

  methods: {
    // 创建应用
    handleOpenCreateApp() {
      if (this.userInfo.memberId) {
        this.$refs.popUpAddUse.isShow = true
      } else {
        this.$message({
          type: 'error',
          message: '请登录哟~',
          center: true
        })
      }
    },

    // 点击登录
    handleLogin() {
      // 判断用户是否登录
      let memberId = sessionStorage.getItem('memberId')
      // console.log("首页的登录memberId");
      // console.log(memberId);
      if (memberId == null || memberId == '' || memberId == undefined) {
        // 没有登录则显示登录弹窗
        this.isShowDialog = true
      }
    },

    // 获取地址栏的URL地址
    getWindowUrlCode() {
      let url = location.href
      // let url = 'https://ai.yilianzh.com/newpc/?code=041hv9000zoLgQ1D0o3005xVv52hv901&state='
      // console.log(url);
      if (url.indexOf('?') != -1) {
        let code = url.split('?')[1].split('=')[1].split('&')[0]
        let userInfo = sessionStorage.getItem('userInfo')
        if (userInfo != undefined && userInfo != '' && userInfo != null) {
          // 登录了则退出
          // this.userInfo = userInfo;
          return
        }
        // 获取用户信息
        getUserInfoApi(code)
          .then((res) => {
            let result = res.data.data
            console.log(result)
            this.userInfo = result.loginSysUserVo
            // this.userstore.userInfo = result.loginSysUserVo;
            // this.userstore.token = result.token;
            let memberId = result.loginSysUserVo.memberId
            sessionStorage.setItem('memberId', memberId)
            sessionStorage.setItem('token', result.token)
            sessionStorage.setItem(
              'userInfo',
              JSON.stringify(result.loginSysUserVo)
            )
            this.handleGetLeaveWords()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    // 剩余词数
    handleGetLeaveWords() {
      getLeaveWordsApi(this.userInfo.memberId).then((res) => {
        console.log(res)
        let sumPoints = 0
        res.data.data.forEach((item) => {
          sumPoints +=
            parseInt(item.chatPointsCount) - parseInt(item.usedPointsCount)
        })
        this.userstore.leavePoints = sumPoints
      })
    },

    // 关闭通知滚动条
    closeMsgBox() {
      this.isShowMsgBox = false
    },

    // 退出登录
    loginOut() {
      // this.$router.push({
      //   path: "/login",
      // });
      this.userInfo = {
        nickname: '请登录',
        headimgurl:
          'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
      }
      loginOutApi().then((res) => {
        sessionStorage.clear()
        this.handleLogin()
      })
    },

    // 跳转官网
    gooFficialWebsite() {
      window.open('http://www.yilianzh.com/#/home', '_blank')
    },

    clear() {
      this.keyword = ''
      this.idShowDel = false
      this.getAppList()
    },

    inputValue(value) {
      this.idShowDel = false
    },

    // 查询标签
    clickNav(index, labelId) {
      // console.log(index);
      // console.log(labelId);
      this.active = index
      this.getAppList({ labelId })
    },

    // 获取应用列表
    getAppList(obj = {}) {
      let data = {
        // appStatus: 1,
        current: 0,
        size: 999,
        ...obj
      }
      getAppListApi(data).then((response) => {
        // console.log(response);
        this.toolList = response.data.data.records
        sessionStorage.setItem('toolList', JSON.stringify(this.toolList))
      })
    },

    // 获取标签列表
    getLabelList() {
      let data = {
        status: 1,
        current: 0,
        size: 999
      }
      // this.$api.post("/api/chatLabel/queryByPage", data).then((response) => {
      getLabelListApi(data).then((response) => {
        this.navList = [
          { labelName: '全部应用' },
          ...response.data.data.records
        ]
        sessionStorage.setItem('navList', JSON.stringify(this.navList))
      })
    },

    // 搜索应用
    searchUse() {
      this.idShowDel = true
      this.active = 0
      this.getAppList({ appTitle: this.keyword })
    },

    // 跳转聊天界面
    goChat() {
      this.$router.push({
        path: '/chat',
        query: { value: this.value }
      })
    },

    // 跳转子应用页面
    goSubapplication(item) {
      this.$router.push({
        path: '/subapplication',
        query: { appId: item.appId }
      })
    },
    // 跳转文档解析界面
    goFileParsing() {
      // this.$router.push({ path: "/fileParsing" });
      this.$router.push({
        path: '/docParsing',
        query: { path: '/docParsing' }
      })
    },

    /**
     * Phase II code
     * Author：Cai
     *
     */
    // 头像下拉点击
    // showBtn() {
    //   this.isShowBtn = !this.isShowBtn;
    // },
    // 更多功能点击下拉
    // showMore(){
    //   this.isShowMore = !this.isShowMore
    // },

    // 横幅点击事件
    bannerClick() {
      this.$router.push({
        path: '/upgrade',
        query: { path: '/upgrade' }
      })
    },

    // 点击图像生成
    imgClick() {
      this.$router.push({
        path: '/createImg',
        query: { path: '/createImg' }
      })
    },

    // 点击同声传译
    transportClick() {
      this.$router.push({
        path: '/translate',
        query: { path: '/translate' }
      })
    },

    // 点击文档解析
    parseClick() {
      this.$router.push({
        path: '/docParsing',
        query: { path: '/docParsing' }
      })
    },

    // 点击智能聊天助手
    chatClick() {
      this.$router.push({ path: '/chat', query: { path: '/chat' } })
    },

    // 点击意见反馈
    adviceClick() {
      if (
        sessionStorage.getItem('token') &&
        sessionStorage.getItem('memberId') &&
        sessionStorage.getItem('userInfo')
      ) {
        window.open('https://docs.qq.com/form/page/DTkhsV0xMR0ViRkxp', '_blank')
      } else {
        this.$message({
          type: 'error',
          message: '请登录哟~',
          center: true
        })
      }
    },

    // 点击体验移动端
    mobileClick() {
      console.log('体验移动端')
    },

    // 点击个人中心
    toPersonalCenterClick() {
      this.$router.push({
        path: '/userInfo',
        query: { path: '/userInfo' }
      })
    }
  },

  watch: {
    // 点击其他位置关闭【个人中心】的显示
    isShowBtn(val) {
      if (val) {
        document.body.addEventListener('click', () => {
          this.isShowBtn = false
        })
      } else {
        document.body.removeEventListener('click', () => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.main {
  width: 1400px;
  margin: 0 auto;
}

.banner {
  width: 100%;
  height: 500px;
  background: url('@/assets/images/home-banner.png');
  background-size: cover;

  .logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 18px;

    > img {
      width: 130px;
      height: 42px;
    }

    .headerBtn {
      color: #fff;
      width: 800px;
      display: flex;
      justify-content: end;
      align-items: center;

      // 功能区
      .functions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .function_list_item {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          margin-right: 40px;
          &:hover {
            cursor: pointer;
            color: #2be2e2;
          }
        }
      }

      // 体验移动端
      .experience_mobile {
        position: relative;
        width: 120px;
        height: 34px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 17px;
        line-height: 34px;
        margin-right: 40px;
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 14px;
          margin-right: 4px;
        }
        > span {
          display: inline-block;
        }

        .qr-code {
          position: absolute;
          right: 0px;
          top: 42px;
          height: 120px;
          width: 120px;
          // background-color: #e4e1e1;
          border-radius: 10px;
          z-index: 999;

          > img {
            height: 120px;
            width: 120px;
          }
        }
      }

      // 用户
      .user {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 130px;
        position: relative;
        color: #ffffff;
        .nickname {
          width: 50px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .nickname:hover {
          cursor: pointer;
        }

        .userSelect {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          position: absolute;
          right: 0px;
          top: 55px;
          width: 115px;
          padding: 10px 0;
          background-color: #717171;
          border-radius: 10px;

          > span {
            font-size: 14px;
            display: inline-block;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &:hover {
              cursor: pointer;
              color: #2be2e2;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  .search-box {
    margin-top: 115px;
    text-align: center;

    .title {
      font-size: 50px;
      font-family: DINCondensed-Bold, DINCondensed;
      font-weight: bold;
      color: #ffffff;
      line-height: 60px;
      letter-spacing: 1px;
      background: linear-gradient(180deg, #29d1d1 0%, #12a4a5 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .document-box {
      width: 200px;
      margin: 0 auto;
      height: 18px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      cursor: pointer;

      .document-icon {
        display: inline-block;
        width: 18px;
        height: 20px;
        margin-right: 5px;
        vertical-align: middle;
        background: url('@/assets/images/document.png');
        background-size: contain;
      }

      span {
        vertical-align: middle;
      }

      &:hover {
        color: #12a4a5;

        .document-icon {
          background: url('@/assets/images/document-active.png');
          background-size: contain;
        }
      }
    }
  }
}

.toolkit {
  padding-top: 24px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title-left {
      span {
        display: inline-block;
        vertical-align: middle;

        &:nth-child(1) {
          width: 6px;
          height: 24px;
          background: linear-gradient(180deg, #29d1d1 0%, #12a4a5 100%);
          border-radius: 4px;
          margin-right: 8px;
        }

        &:nth-child(2) {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .search {
      width: 192px;
      height: 34px;
      background: #eeeeee;
      border-radius: 17px;
      padding: 10px 16px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 16px;

      input {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        color: #666;
        font-size: 14px;
        margin-right: 25px;

        &::placeholder {
          font-size: 14px;
          color: #999;
        }
      }

      img {
        width: 18px;
        height: 17px;
        cursor: pointer;
      }
    }

    .msg-box {
      position: relative;

      .msg-banner {
        width: 700px;
        cursor: pointer;
      }
      .more-info {
        position: absolute;
        right: 90px;
        bottom: 11px;
        // height: 18px;
        // line-height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #28c4c4;
        display: flex;
        align-items: center;
        > img {
          margin-left: 4px;
        }
      }
      .more-info:hover {
        cursor: pointer;
      }

      > i {
        position: absolute;
        right: 6px;
        bottom: 12px;
      }
    }
  }

  .toolkit-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .nav-box {
      width: 220px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 100%;

      > div {
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 1px solid #f1f3f7;

        span {
          color: #333;
        }

        .icon {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 16px;
        }

        .icon0 {
          background: url('@/assets/images/tool-icon01.png');
          background-size: 100% 100%;
        }

        .icon1 {
          background: url('@/assets/images/tool-icon11.png');
          background-size: 100% 100%;
        }

        .icon2 {
          background: url('@/assets/images/tool-icon21.png');
          background-size: 100% 100%;
        }

        .icon3 {
          background: url('@/assets/images/tool-icon31.png');
          background-size: 100% 100%;
        }

        .icon4 {
          background: url('@/assets/images/tool-icon41.png');
          background-size: 100% 100%;
        }

        .icon5 {
          background: url('@/assets/images/tool-icon51.png');
          background-size: 100% 100%;
        }

        &:hover,
        .active {
          span {
            color: #333;
            transition: all 0.3s ease-in-out;
          }

          .icon0 {
            background: url('@/assets/images/tool-icon0.png');
            background-size: 100% 100%;
          }

          .icon1 {
            background: url('@/assets/images/tool-icon1.png');
            background-size: 100% 100%;
          }

          .icon2 {
            background: url('@/assets/images/tool-icon2.png');
            background-size: 100% 100%;
          }

          .icon3 {
            background: url('@/assets/images/tool-icon3.png');
            background-size: 100% 100%;
          }

          .icon4 {
            background: url('@/assets/images/tool-icon4.png');
            background-size: 100% 100%;
          }

          .icon5 {
            background: url('@/assets/images/tool-icon5.png');
            background-size: 100% 100%;
          }
        }

        &:hover {
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 10px 16px 0px rgba(18, 164, 165, 0.1);

          span {
            color: #12a4a5;
          }
        }

        .active {
          font-weight: 600;
        }
      }

      .active-box {
        transition: all 0.3s ease-in-out;
      }
    }

    // 内容
    .main {
      width: 1156px;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 24px;
      text-align: left;
      margin: 0;

      // height: 100%;
      > div {
        display: inline-block;
        vertical-align: top;
        width: 259px;
        height: 130px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        margin-right: 24px;
        margin-bottom: 24px;
        padding: 24px 16px;
        cursor: pointer;

        &:nth-child(4n) {
          margin: 0;
        }

        .name {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-bottom: 8px;

          &::before {
            content: '';
            width: 0px;
            height: 0px;
            background: #12a4a5;
            border-radius: 50%;
            margin-right: 0px;
          }
        }

        .info {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #666666;
          line-height: 20px;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .arrows {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span:nth-child(1) {
            font-size: 12px;
            color: #666666;
            line-height: 12px;
          }

          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('@/assets/images/tool-defualt.png');
            background-size: cover;
          }
        }

        &:hover {
          box-shadow: 0px 10px 16px 0px rgba(18, 164, 165, 0.1);
          border-color: #12a4a5;
          transition: all 0.3s ease-in-out;

          .name {
            color: #12a4a5;
            transition: all 0.3s ease-in-out;

            &::before {
              display: block;
              content: '';
              width: 4px;
              height: 4px;
              background: #12a4a5;
              border-radius: 50%;
              margin-right: 6px;
              transition: all 0.3s ease-in-out;
            }
          }

          .arrows {
            .icon {
              background: url('@/assets/images/tool-active.png');
              background-size: cover;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }

      .more {
        background: #f9fafb;
        border: 1px dashed #e5e5e5;

        > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 26px;
            height: 28px;
            margin-bottom: 8px;
          }

          div {
            font-size: 14px;
            color: #cccccc;
            line-height: 14px;
            text-align: center;
          }
        }

        &:hover {
          background: #f9fafb;
          border: 1px dashed #e5e5e5;
          box-shadow: none;
        }
      }
    }
  }
}

.remind-block {
  position: relative;
  overflow: hidden;
  height: 80px;
  line-height: 80px;
  padding: 0 150px;
  background-color: #2be3e3;
  color: #fff;
  text-align: center;
  font-size: 25px;

  .marquee {
    animation: marquee 10s linear infinite;
    white-space: nowrap;
    position: absolute;
  }

  .marqueeT {
    animation: marqueeT 10s linear infinite;
    white-space: nowrap;
    position: absolute;
  }

  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -105%;
    }
  }

  @keyframes marqueeT {
    0% {
      left: 105%;
    }

    100% {
      left: 0;
    }
  }

  .link {
    color: #ffc83d;
    font-weight: 700;
    cursor: pointer;
  }
}
// 修改通知滚动栏
::v-deep .van-notice-bar__wrap {
  margin-right: 10px;
}

::v-deep .el-dialog {
  border-radius: 10px;
}
</style>
