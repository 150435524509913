<template>
  <div class="footer-inpout">
    <div class="flex items-center pl-10 pr-10 py-14">
      <img
        src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/icon-expand-more.png"
        class="w-26 h-26"
        @click="handleExpand"
      />
      <div class="flex-1 custom-input px-10">
        <van-field
          v-model="keyword"
          ref="input"
          rows="1"
          :autosize="autosize"
          type="textarea"
          :placeholder="placeholder"
          @keyup.enter="handleSend"
        >
          <!-- <template #button>
            <img
              src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/icon-voice.png"
              class="w-15 h-15"
            />
          </template> -->
        </van-field>
      </div>
      <img
        src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/icon-send.png"
        class="w-26 h-26"
        @click="handleSend"
      />
    </div>
  </div>
</template>
<script>
import { eventBus } from '@/main'
export default {
  name: 'YlChatWebFooterVoiceInpout',
  props: {
    placeholder: {
      type: String,
      default: '请输入要咨询的问题'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      keyword: '',
      autosize: { maxHeight: 50 }
    }
  },
  watch: {
    value: {
      handler(newVal) {
        console.log(newVal, 'newVal')
      },
      immediate: true
    }
  },

  mounted() {},

  methods: {
    handleSend() {
      this.$emit('changeSend', this.keyword)
      this.$refs.input.blur()
      this.keyword = ''
    },
    handleExpand() {
      eventBus.$emit('expand')
      // this.$emit('expand')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-inpout {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 50px;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}
.custom-input {
  ::v-deep .van-cell {
    background: #f9fafc;
    border-radius: 34px;
  }
  ::v-deep .van-field__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
