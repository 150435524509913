<template>
  <div class="flex flex-col">
    <!-- 导航栏 -->
    <navbar :showBack="false" title="Elion Chat" :showShare="false"></navbar>

    <!-- 正文 -->
    <div class="content-view" ref="chatContent">
      <div v-for="(item, index) in list" :key="index" class="mb-14">
        <div class="item-assistant-content" v-if="item.role == 'assistant'">
          <div class="flex">
            <img
              src="@/assets/images/AIuser.png"
              alt=""
              class="w-34 h-34 mr-10"
            />
            <div class="flex-1 mr-50">
              <div class="reasoning-loading" v-if="item.reasoning_content">
                <img
                  v-if="item.reasoning_load"
                  class="mr-3 rotate w-14 h-14"
                  src="@/assets/images/loading-2.png"
                  alt=""
                />
                <img
                  v-else
                  class="mr-3 w-14 h-14"
                  src="@/assets/images/complete.png"
                  alt=""
                />
                {{ item.reasoning_load ? '正在推理搜索...' : '已完成推理' }}
              </div>
              <div class="reason-answer-content">
                <div class="chat-reply mb-15" v-if="item.reasoning_content">
                  <div class="answer-context reasoning-content">
                    <v-md-editor
                      :value="item.reasoning_content"
                      mode="preview"
                      @copy-code-success="handleCopyCodeSuccess"
                    ></v-md-editor>
                  </div>
                </div>
                <div class="chat-reply">
                  <div class="answer-context content-text" v-if="item.content">
                    <v-md-editor
                      :value="item.content"
                      mode="preview"
                    ></v-md-editor>
                    <span
                      v-if="index === list.length - 1 && loading"
                      class="cursor"
                      v-cursor
                    ></span>
                  </div>

                  <div
                    class="flex items-center"
                    v-if="index === list.length - 1 && index !== 0"
                  >
                    <img
                      v-if="loading"
                      style="width: 16px; height: 16px"
                      class="rotate"
                      src="@/assets/images/loading-3.png"
                      alt=""
                    />
                    <img
                      v-if="!loading && item.content"
                      @click="changeResponse"
                      src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/icon-Refresh.png"
                      class="w-16 h-16"
                    />
                    <img
                      v-if="!loading && item.content"
                      @click="copyText(item.content, $event)"
                      src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/icon-copy.png"
                      class="w-16 h-16 ml-16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- //自己输入的内容 -->
        <div v-else class="user-content">
          <div class="user-answer flex-1 ml-50">
            {{ item.content }}<span class="icon"></span>
          </div>
          <img src="@/assets/images/user.png" alt="" class="w-34 h-34 ml-10" />
        </div>
      </div>
    </div>

    <!-- 底部输入框 -->
    <footer-voice-inpout
      :value.sync="value"
      @changeSend="changeSubmit"
    ></footer-voice-inpout>
    <popupShowBox @change="changeItemChat" />
  </div>
</template>

<script>
import navbar from '@/components/content/navbar.vue'
import footerVoiceInpout from '@/views/mobile/footer-voice-inpout.vue'
import { eventBus } from '@/main'
import { useUserStore } from '@/store/user'
import MarkdownIt from 'markdown-it'
import mdAttrs from 'markdown-it-attrs'
import handleCopy from '@/util/clipboard'
import popupShowBox from '@/views/mobile/chat/popup-show-box.vue'
import { getChatDetailApi } from '@/api/chat'
import { Toast } from 'vant'

const md = new MarkdownIt().use(mdAttrs)
const DEFULT_LIST = {
  role: 'assistant',
  content:
    '你好呀，我是亿链Chat，请输入你想问的问题，我来帮你回答；要详细的描述你的问题，我才能更加准确的生成答案噢。',
  reasoning_content: '',
  reasoning_load: false,
  defule: 1
}
export default {
  components: {
    navbar,
    footerVoiceInpout,
    popupShowBox
  },
  data() {
    return {
      value: '',
      list: [],
      listCopy: [
        {
          role: 'assistant',
          content:
            '你好呀，我是亿链Chat，请输入你想问的问题，我来帮你回答；要详细的描述你的问题，我才能更加准确的生成答案噢。'
        }
      ], // 第一次输入未返回数据默认值.
      userstore: useUserStore(),
      localRoomId: '',
      type: 1,
      loading: false,
      parentMessageId: '',
      memberId: '',
      reAnswer: 0
    }
  },
  async mounted() {
    // sessionStorage.setItem(
    //   'userInfo',
    //   '{"isAdmin":"2","isOperation":"1","memberId":"1892130797415112705","openid":"oS11W6ODFgRczkNuF8fJBslLNCZA","nickname":"生活不易-小张叹气","sex":"0","province":"","city":"","country":"","headimgurl":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqgtBDywvjS9Tia5KLVBIaLWUOp7OamXJzr8JSpibFKM2PzSwk03KcC1aAIWPfQVWLEj7rG4rbXDW2Q/132","unionid":"o9mX35raIw6eLuihkpttMlRTWWo0","createTime":"2025-02-19 16:35:11","memberType":{"value":"2","label":"免费会员"},"invitationCode":"777963"}'
    // )
    // sessionStorage.setItem('memberId', '1892130797415112705')
    // sessionStorage.setItem(
    //   'token',
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3ZWIiLCJpc3MiOiJlbGlvbi1jaGF0IiwiZXhwIjoxNzQzMTQzMjE4LCJpYXQiOjE3NDA1NTEyMTgsImp0aSI6IjE4OTIxMzA3OTc0MTUxMTI3MDUiLCJ1c2VybmFtZSI6IueUn-a0u-S4jeaYky3lsI_lvKDlj7nmsJQifQ.kJZvX4ttbSNZqP94wapzll-fT5bKuSSWUShPS--iQcU'
    // )
    this.list = [DEFULT_LIST]
    console.log(this.list)
    await this.connectSocket()
    // 监听websocket已连接成功
    eventBus.$on('websocket-opened', () => {
      if (this.$route.query.value) {
        this.value = this.$route.query.value
        this.submit() // 如果路由参数【输入的值】有值就调用提交方法
      }
    })
    // 监听websocket已断开连接
    eventBus.$on('websocket-close', () => {
      console.log('重新连接中...')
      this.connectSocket()
    })
  },
  methods: {
    async connectSocket() {
      await this.$socket.connect(sessionStorage.getItem('token'))
      this.$socket.addEventListener('message', (event) => {
        let json_data = JSON.parse(event.data)
        this.localRoomId = json_data.currentId
        if (json_data.content == '[DONE]') {
          let usedPoints = json_data.usedPoints
          this.userstore.leavePoints -= usedPoints
          this.list[this.list.length - 1].content = md.render(
            this.list[this.list.length - 1].content
          )
          this.type = 1
          this.loading = false
          return
        }
        // 推理数据
        if (json_data.reasoning_content) {
          this.setText(json_data.reasoning_content, 2)
          this.list[this.list.length - 1].reasoning_load = true
        }
        if (json_data.content) {
          this.list[this.list.length - 1].reasoning_load = false
        }
        if (json_data.content == null || json_data.content == 'null') {
          return
        }
        this.setText(json_data.content)
        this.type = 2
      })
    },
    // 更新回答
    setText(text, type = 1) {
      if (type == 1) {
        this.list[this.list.length - 1].content += text
        this.list = JSON.parse(JSON.stringify(this.list))
      } else {
        this.list[this.list.length - 1].reasoning_content += text
        this.list = JSON.parse(JSON.stringify(this.list))
      }
      this.scollBottom()
    },
    // 提交
    changeSubmit(keyword) {
      console.log(keyword)
      this.value = keyword
      if (!this.value || this.loading) return
      this.loading = true
      let params = {
        role: 'user',
        content: this.value,
        reasoning_content: '',
        reasoning_load: false
      }
      this.list.push(params)
      // 移除数据中defule为-1的数据
      this.list = this.list.filter((item) => item.defule !== 1)

      // 新建聊天在左侧显示【新建的标题】
      if (this.list[0].role === 'assistant' && this.list.length <= 2) {
        console.log('将标题传递给左侧...')
        const data = {
          title: this.value,
          roomId: ''
        }
      }
      // 本地聊天 还没有提交的聊天
      if (this.localRoomId == '') {
        this.listCopy = [
          {
            role: 'role',
            content: this.value
          }
        ]
      }
      let string = {
        modelName: this.userstore.chatGptModule,
        parentMessageId: this.parentMessageId,
        msg: this.value,
        reAnswer: this.reAnswer, //是否重新回答，0.否 ,1.是
        // 这里的AppId请使用字符串形式传递，不然会造成精度丢失的问题
        sendType: 3 //消息类型, 0.心跳检测, 1.App测试(不会存储上下文), 2.正式App(根据上下文回答), 3.普通聊天, 4.文件分析, 5.同声传译
      }
      console.log(string, 'string')

      // 提交消息，将用户输入的内容转换成字符串并发送
      this.$socket.send(JSON.stringify(string)) // 发送消息
      this.value = ''
      if (this.type === 1) {
        this.list.push({
          role: 'assistant',
          content: '',
          reasoning_content: '',
          reasoning_load: false
        })
      }
    },
    changeResponse() {
      this.reAnswer = 1
      const value = this.list[this.list.length - 2].content
      this.list.splice(this.list.length - 2, 2)
      console.log(value, 123456)
      this.changeSubmit(value)
    },

    // 滚动到底部
    scollBottom() {
      const chatContent = this.$refs.chatContent
      if (chatContent) {
        setTimeout(() => {
          chatContent.scrollTop = chatContent.scrollHeight // 滚动到底部
        }, 300)
      }
    },
    handleCopyCodeSuccess(e) {
      console.log(e, 123)
    },
    copyText(text, e) {
      const val = this.convertHtmlToPlainText(text)
      handleCopy(
        val,
        e,
        () => {
          Toast('复制成功')
        },
        () => {
          Toast.fail('复制失败')
        }
      )
    },
    convertHtmlToPlainText(html) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = html
      return tempDiv.innerText
    },
    changeItemChat(data) {
      console.log(data)
      if (data.socket) {
        this.connectSocket()
        this.loading = false
      }
      this.localRoomId = data.roomId
      // data.roomId 为空 表示新建聊天
      if (data.roomId) {
        this.parentMessageId = ''
        this.getHistoryChatList()
      } else {
        this.list = [DEFULT_LIST]
        this.parentMessageId = null
        this.reAnswer = 0
      }
    },
    getHistoryChatList() {
      getChatDetailApi({
        chatRoomId: this.localRoomId,
        size: 9999
      }).then((res) => {
        console.log(res, 66666)
        this.list = []
        const textContent = res.data.data.records
        this.userstore.chatGptModule = textContent[0].modelName
        this.parentMessageId = textContent[textContent.length - 1].messageId
        textContent.forEach((item) => {
          if (item.messageType.label === 'assistant') {
            this.list.push({
              role: 'assistant',
              content: item.messageContent
            })
          }
          if (item.messageType.label === 'user') {
            this.list.push({
              role: 'user',
              content: item.messageContent
            })
          }
        })
        this.scollBottom()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-view {
  padding: 12px;
  height: calc(100vh - 160px);
  overflow-y: auto;
  box-sizing: border-box;
  .reasoning-loading {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .reason-answer-content {
    background: #ffffff;
    padding: 10px;

    box-shadow: 0px 1px 16px 0px rgba(0, 53, 53, 0.1);
  }
  .answer-context {
    text-align: left;
    position: relative;
    ::v-deep * {
      font-size: 15px;
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 0;
    //   height: 0;

    //   top: 13px;
    //   left: -13px;
    //   border-top: 10px solid transparent;
    //   border-bottom: 10px solid transparent;
    //   border-right: 15px solid #fff;
    // }
  }
  .reasoning-content {
    ::v-deep * {
      font-size: 14px;
      color: #999;
    }
  }
  .user-content {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .user-answer {
      text-align: left;
      background: #ffffff;
      box-shadow: 0px 1px 16px 0px rgba(0, 53, 53, 0.1);
      padding: 10px;
      font-size: 14px;
    }
  }
  ::v-deep .github-markdown-body {
    padding: 0;
  }
}
</style>
