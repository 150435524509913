import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import api from "./api";
import * as util from "./util";
import WebSocket from "./util/websocket";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
/* 亿链二期新增 author：Cai */
// 引入iconfont库
import "./assets/iconfont/iconfont.css";
// 引入vant组件库
import Vant from "vant";
import "vant/lib/index.css";
//引入全局样式
// import "./assets/styles/global.scss";
import "./style/global.scss";
import Toast from "./components/Toast";
import copyText from "./util/copy";
// 全局使用字体
import "@/assets/font/font.css";

// import VConsole from 'vconsole';
// let vConsole = new VConsole();
// 使用rem移动端适配
// import "amfe-flexible";

// markdown 样式转化
// import showdown from "showdown";
// Vue.use(showdown);
// import hljs from "highlight.js";
// import "highlight.js/styles/default.css";
// import "../node_modules/github-markdown-css/github-markdown.css";

// 代码高亮
Vue.directive("highlight", function (el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});

//导入v-md-edit 插件
import VMdEditor from '@kangc/v-md-editor';
//导入v-md-edit 插件样式
import '@kangc/v-md-editor/lib/style/base-editor.css';
//导入代码高亮模式，为github模式
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
//导入代码高亮样式
import '@kangc/v-md-editor/lib/theme/style/github.css';
//导入highlightjs
import hljs from 'highlight.js';
//全局使用 v-md-edit 插件
Vue.use(VMdEditor);
//配置v-md-edit插件的代码高亮为github模式，使用highlight.js
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
VMdPreview.use(createCopyCodePlugin());
Vue.use(VMdPreview);
// 使用Pinia状态机
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// 图片预览插件
Vue.use(preview);

Vue.use(Vant);

export const eventBus = new Vue();

Vue.use(Toast);

Vue.prototype.$socket = WebSocket;

Object.keys(util).forEach((key) => {
  Vue.prototype[`$${key}`] = util[key];
});

Vue.use(ElementUI);
Vue.config.productionTip = false;

Vue.prototype.$api = api;

Vue.mixin({
  methods: {
    copyText,
  },
});
new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
