<template>
  <div>
    <van-popup
      v-model="show"
      position="left"
      overlay-class="custom-overlay"
      :style="{
        height: '100%',
        width: '65%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }"
    >
      <div class="wp-100 hp-100 flex flex-col px-10 py-10">
        <div class="new-box flex items-center justify-center" @click="changAdd">
          <img
            src="
https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/add.svg"
          />
          新建聊天
        </div>
        <div class="flex-1 my-10 overflow-auto">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              v-for="item in list"
              :key="item.roomId"
              class="item-list flex items-center py-14"
              :class="{ 'active-list': activeId === item.roomId }"
              @click="detileChat(item)"
            >
              <img
                src="
https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/chat-active.svg"
                class="w-15 h-15"
                v-if="activeId === item.roomId"
              />
              <img
                v-else
                src="
https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/chat-default.svg"
                class="w-15 h-15"
              />
              <div
                v-html="item.roomTitle"
                class="flex-1 px-10 text-left ellipsis"
              ></div>
              <img
                src="
https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/arrow.svg"
                class="w-6 h-11"
              />
            </div>
          </van-list>
        </div>
        <div class="shop-box flex items-center justify-center">购买词数</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { postChatList } from '@/api/chat'
import { eventBus } from '@/main'
export default {
  name: 'YlChatWebPopupShowBox',

  data() {
    return {
      show: false,
      list: [],
      loading: false,
      finished: false,
      queryData: {
        appId: '',
        current: 1,
        memberId: '',
        size: 30
      },
      activeId: null
    }
  },

  mounted() {
    // 判断用户是否登录
    let memberId = sessionStorage.getItem('memberId')
    if (memberId !== null && memberId !== '') {
      this.queryData.memberId = memberId
    }
    eventBus.$on('expand', () => {
      this.show = true
      this.queryData.current = 1
      this.getList()
    })
  },

  methods: {
    onLoad() {
      this.loading = true
      this.queryData.current += 1
      console.log(123465)
      this.getList()
    },
    getList() {
      postChatList(this.queryData).then((res) => {
        console.log(res)
        this.list = [...this.list, ...res.data?.data?.records]

        this.loading = false
        this.finished = res.data?.data?.current >= res.data?.data?.pages
      })
    },
    changAdd() {
      this.detileChat()
    },
    detileChat(item) {
      const data = {
        socket: true,
        roomId: item?.roomId || null,
        roomTitle: item?.roomTitle ? item?.roomTitle?.split('\n')[0] : null
      }
      this.activeId = item?.roomId || null
      this.show = false
      // 关闭socket
      this.$socket.close()
      this.$emit('change', data)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .custom-overlay {
  background: transparent !important;
}
.new-box {
  border-radius: 10px;
  border: 2px solid #999999;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  img {
    width: 16px;
    height: 16px;
  }
}
.shop-box {
  background-image: url(https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat/buttonBg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 80%;
  height: 60px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 15px;
  color: #533b00;
}
.item-list {
  color: #fff;
}
.active-list {
  color: #28c4c4;
}
</style>
