import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/pc/home";
import mHome from "@/views/mobile/home/Home.vue";
import chat from "@/views/mobile/chat/chat.vue";
import { Message } from "element-ui";

Vue.use(VueRouter);

// PC端路由
const routesP = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      keepAlive: true, // 标记需要缓存
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/pc/login/wxLogin.vue"),
  },
  {
    path: "/chatAssistant",
    component: () => import("@/views/pc/chatAssistant.vue"),
    meta: { title: "智能聊天助手" },
  },
  {
    path: "/subapplication",
    component: () => import("@/views/pc/subapplication.vue"),
    meta: { title: "智能小助手" },
  },
  {
    path: "/fileParsing",
    component: () => import("@/views/pc/fileParsing.vue"),
    meta: { title: "文档解析助手" },
  },
  {
    path: "/statement",
    component: () => import("@/views/pc/statement.vue"),
    meta: { title: "免责申明" },
  },
  {
    path: "/featuresList",
    component: () => import("@/views/pc/featuresList/FeaturesList.vue"),
    children: [
      {
        path: "/docParsing",
        component: () => import("@/views/pc/featuresList/childComps/DocParsing.vue"),
      },
      {
        path: "/createImg",
        component: () => import("@/views/pc/featuresList/childComps/createImg.vue"),
      },
      {
        path: "/translate",
        component: () => import("@/views/pc/featuresList/childComps/Translate.vue"),
      },
    ],
  },
  {
    path: "/chat",
    component: () => import("@/views/pc/featuresList/childComps/chat/Chat.vue"),
  },
  // 个人中心页面
  {
    path: "/personalCenter",
    component: () => import("@/views/pc/personalCenter/PersonalCenter.vue"),
    children: [
      {
        path: "/userInfo",
        component: () => import("@/views/pc/personalCenter/childComps/userinfo/UserInfo.vue"),
      },
      {
        path: "/aboutUs",
        component: () => import("@/views/pc/personalCenter/childComps/aboutus/AboutUs.vue"),
      },
      {
        path: "/orderData",
        component: () => import("@/views/pc/personalCenter/childComps/orderdata/OrderData.vue"),
      },
      {
        path: "/upgrade",
        component: () => import("@/views/pc/personalCenter/childComps/upgrade/Upgrade.vue"),
      },
    ],
  },

  // 旧
  {
    path: "/ChatArea",
    component: () => import("@/views/ChatArea/index"),
    meta: { title: "聊天" },
  },
];

// 移动端路由
const routesM = [
  {
    path: "/",
    component: () => import("@/views/mobile/layout.vue"),
    redirect: "/mobile/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/mobile/home/Home.vue"),
      },

      {
        path: "/",
        name: "chat",
        component: chat,
        component: () => import("@/views/mobile/chat/chat.vue"),
        meta: {
          keepAlive: true, // 标记需要缓存
        },
      },

      // 我的
      {
        path: "/mine",
        component: () => import("@/views/mobile/mine/mine.vue"),
      },
      {
        path: "/userInfo",
        component: () => import("@/views/mobile/mine/UserInfo.vue"),
      },
      {
        path: "/orderData",
        component: () => import("@/views/mobile/mine/OrderData.vue"),
      },
      {
        path: "/orderDetail",
        component: () => import("@/views/mobile/mine/OrderDetail.vue"),
      },
      {
        path: "/buyWords",
        component: () => import("@/views/mobile/mine/BuyWords.vue"),
      },
      {
        path: "/aboutUs",
        component: () => import("@/views/mobile/mine/AboutUs.vue"),
      },
      {
        path: "/loginOut",
        component: () => import("@/views/mobile/mine/LoginOut.vue"),
      },
    ]
  }
];

var routes = [];
// 判断 移动端/PC端
// if (
//   navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   )
// ) {
//   routes = routesM;
// } else {
//   routes = routesP;
// }

routes = routesP;

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

/* 路由守卫，检验是否登录 */
router.beforeEach((to, from, next) => {
  // console.log("routes", routes);
  console.log(to, from, 'tofrom,next');
  if (routes[0].name === "Home") {
    // PC端
    // console.log("PC端");
    if (to.name !== "Home") {
      let userInfo = sessionStorage.getItem("userInfo");
      if (userInfo != null) {
        // 判断用户是否登录
        next();
      } else {
        // 没有登录就跳转到登录页
        Message({
          type: "error",
          message: "请登录哟~",
          center: true,
        });
        router.push({ path: "/" });
      }
    } else {
      next();
    }
  } else {
    // 手机端
    // console.log("手机端");
    next();
  }
});

export default router;
