<template>
  <div>
    <van-notice-bar
      left-icon="volume-o"
      text="在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。"
    />
    <van-field v-model="value" label="文本" placeholder="请输入用户名" />
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
