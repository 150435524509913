import request from "./index";
// import qs from 'qs'

// 获取聊天列表
export const postChatList = (data) => {
    return request({
        url: "/api/chatRoom/queryByPage",
        method: "POST",
        data: data,
    });
};

// 获取聊天详情页
export const getChatDetailApi = (data) => {
    return request({
        url: "/api/chatMessage/getMessageList",
        method: "GET",
        params: data,
    });
};

// 通过appId获取数据
export const getAppInfoApi = (appId) => {
    return request({
        url: `/api/chatApps/${appId}`,
        method: "GET",
    });
};

// 调用聊天chatGPT接口
export const postChatApi = (data) => {
    return request({
        url: "/api/chatBoot",
        method: "POST",
        data: data,
    });
};

